<template>
  <div>
    <!--  style="min-height: 100vh; position: relative" -->
    <!-- 头部 -->
    <Header :dlogBr="true" val="yes"></Header>
    <!-- 进度条 -->
    <Progress :val="2"></Progress>

    <div class="neiron">
      <div class="left">
        <!-- 填写资料 -->
        <div class="formBox">
          <div class="formBox-tit">*Required Field</div>
          <div class="formBox-form">
            <el-form
              :rules="passengerInfoRules"
              ref="passengerInfoRef"
              label-position="top"
              label-width="80px"
              :model="pasInfoForm"
            >
              <div style="display: flex; justify-content: space-between">
                <el-form-item prop="firstName" :label="'First Name'">
                  <el-input
                    v-model="pasInfoForm.firstName"
                    maxlength="30"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="lastName" :label="'Last Name'">
                  <el-input
                    v-model="pasInfoForm.lastName"
                    maxlength="30"
                  ></el-input>
                </el-form-item>
              </div>

              <div style="display: flex; justify-content: space-between">
                <el-form-item prop="email" :label="'Email Address'">
                  <el-input v-model="pasInfoForm.email"></el-input>
                </el-form-item>
                <el-form-item
                  prop="affirmEmail"
                  :label="'Confirm Email Address'"
                >
                  <el-input v-model="pasInfoForm.affirmEmail"></el-input>
                </el-form-item>
              </div>

              <!-- 隐私政策 -->
              <div class="el-form-chebox">
                <el-form-item prop="chebox">
                  <el-checkbox-group v-model="pasInfoForm.chebox">
                    <el-checkbox @change="yueduTyFn" name="chebox"
                      >l have read and agreed to the
                      <a @click="serviceDialogBr = true" href="javascript:;"
                        >Terms of Service</a
                      >
                      &
                      <a @click="policyDialogBr = true" href="javascript:;"
                        >Privacy Policy</a
                      >
                      &
                      <a href="javascript:;" @click="noticeDialogBr = true"
                        >Notice</a
                      >.
                      <!-- <el-popover
                        popper-class="homePopover"
                        placement="top"
                        title="Moominvalley Park 1-Day Pass"
                        trigger="hover"
                      >
                        <div class="mainDlg">
                          <p>
                            • Refundable three days before the day of visit.
                          </p>
                          <p>
                            • Re-entering the park on a valid day is allowed.
                            Please show the verified ticket to the staff when
                            re-entering.
                          </p>
                          <p>
                            • When guests enter the Park, click the E-ticket URL
                            and the E-ticket will be displayed on the page.
                            Confirm your booking information for the E-ticket,
                            then press the "Confirm" button, and show the
                            verified ticket to the staff.
                          </p>
                          <p>
                            • When purchasing more than one ticket, each guest
                            has to show the verified ticket to the staff.
                          </p>
                          <p>
                            • Reselling tickets or providing any ticket for the
                            purpose of reselling to a third party is strictly
                            prohibited whether it is for profit or not.
                          </p>
                          <p>
                            • Tickets obtained by violating any of these
                            prohibitions will be void. Refunds of those tickets
                            are not allowed.
                          </p>
                        </div>
                        <a href="javascript:;" slot="reference">Notice</a>
                        <a style="color: #12334c; cursor: pointer">
                          <span></span>
                        </a>
                      </el-popover> -->
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>

                <el-form-item prop="chebox2">
                  <el-checkbox-group v-model="pasInfoForm.chebox2">
                    <el-checkbox @change="yueduTyFn2" name="chebox"
                      >Please note that if the above information is incomplete
                      or incorrect, we reserve the right to cancel the
                      order.</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
        <!-- 总价 按钮 -->
        <div v-if="param" class="totalBtn">
          <div class="totalBtn-lft">Total</div>
          <div v-if="param" class="totalBtn-rig">
            <p>￥{{ setSumPrice(param.totalPrice, 1) || "-" }}</p>
            <el-button :loading="btnLoadingBr" @click="continueBtn" class="totalBtn-btn"
              >Continue</el-button
            >
          </div>
        </div>
      </div>
      <div class="right">
        <div v-if="param" class="right-tit">
          <p>Moominvalley Park 1-Day Pass</p>
          <span
            >{{ translateMonth(param.date.split("-")[1]) }}
            {{ param.date.split("-")[2] }}, {{ param.date.split("-")[0] }}</span
          >
        </div>
        <div v-if="param && param.adult" class="right-adult">
          <div class="item">
            <p>Quantity</p>
            <span>{{ param.adult ? param.adult + "x" : "-" }} Adult</span>
          </div>
          <div class="item">
            <p>Subtotal</p>
            <span
              >￥{{ setSumPrice(param.adultPrice, param.adult) || "-" }}</span
            >
          </div>
        </div>
        <div v-if="param && param.child" class="right-child">
          <div class="item">
            <p>Quantity</p>
            <span>{{ param.child ? param.child + "x" : "-" }} Child</span>
          </div>
          <div class="item">
            <p>Subtotal</p>
            <span
              >￥{{ setSumPrice(param.childPrice, param.child) || "-" }}</span
            >
          </div>
        </div>
        <div v-if="param && param.student" class="right-child">
          <div class="item">
            <p>Quantity</p>
            <span>{{ param.student ? param.student + "x" : "-" }} Secondary Student</span>
          </div>
          <div class="item">
            <p>Subtotal</p>
            <span
              >￥{{ setSumPrice(param.studentPrice, param.student) || "-" }}</span
            >
          </div>
        </div>
        <!-- 总价 -->
        <div v-if="param" class="right-total">
          <p>Total</p>
          <span>￥{{ setSumPrice(param.totalPrice, 1) || "-" }}</span>
        </div>
      </div>
    </div>
    <!-- 
    <div class="btm">
      <div class="btm-box">
        <span>Contact Us</span>
        <p>
          Need Assistance with your Order ? please email <a
            href="mailto:order@moominvalleypark-booking.com"
            >order@moominvalleypark-booking.com</a
          >
        </p>
      </div>
    </div> -->

    <!-- 链接弹窗 -->

    <div class="lianjieDialogBox">
      <div class="nextDialog">
        <el-dialog
          :visible.sync="serviceDialogBr"
          width="93vw"
          :before-close="handleClose"
          :title="serviceHtml.title"
        >
          <div class="neir">
            <div v-html="serviceHtml.content" class="neir-box"></div>
          </div>
          <!-- 底部占位盒子 -->
          <div style="width: 100%; height: 100px"></div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="serviceDialogBr = false">Close</el-button>
          </span>
        </el-dialog>
      </div>

      <div class="nextDialog">
        <el-dialog
          :visible.sync="policyDialogBr"
          width="93vw"
          :before-close="handleClose"
          :title="privacyHtml.title"
        >
          <div class="neir">
            <div v-html="privacyHtml.content" class="neir-box"></div>
          </div>
          <!-- 底部占位盒子 -->
          <div style="width: 100%; height: 100px"></div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="policyDialogBr = false">Close</el-button>
          </span>
        </el-dialog>
      </div>

      <div class="nextDialog">
        <el-dialog
          :visible.sync="noticeDialogBr"
          width="93vw"
          :before-close="handleClose"
          :title="precautionsHtml.title"
        >
          <div class="neir">
            <div v-html="precautionsHtml.content" class="neir-box"></div>
          </div>
          <!-- 底部占位盒子 -->
          <div style="width: 100%; height: 100px"></div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="noticeDialogBr = false">Close</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Progress from "@/components/progress.vue";

import {
  post_orderInfo,
  get_service,
  get_privacy,
  get_precautions,
} from "@/api/common.js";
export default {
  data() {
    return {
      btnLoadingBr: false, // 按钮请求状态
      serviceHtml: "",
      privacyHtml: "",
      precautionsHtml: "",
      noticeDialogBr: false,
      policyDialogBr: false,
      serviceDialogBr: false,
      param: JSON.parse(localStorage.getItem("orderParam")) || {}, // 订单参数
      btnBr: false, // 按钮样式
      btnBr2: false, // 按钮样式
      pasInfoForm: JSON.parse(localStorage.getItem("pasInfoForm")) || {
        firstName: "",
        lastName: "",
        email: "",
        affirmEmail: "",
        chebox: [],
        chebox2: [],
      },
      passengerInfoRules: {
        firstName: [
          {
            required: true,
            message: "Please enter your first name",
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please enter your last name",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please enter a valid email address",
            trigger: "blur",
          },
          {
            pattern:
              /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            message: "Please enter a valid email address",
            trigger: "blur",
          },
        ],
        affirmEmail: [
          {
            required: true,
            message: "Please enter a valid email address",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("Please enter a valid email address"));
              } else if (value !== this.pasInfoForm.email) {
                callback(new Error("Please enter a valid email address"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        chebox: [
          {
            type: "array",
            required: true,
            message: " ",
            trigger: "change",
          },
        ],
        chebox2: [
          {
            type: "array",
            required: true,
            message: " ",
            trigger: "change",
          },
        ],
      },
    };
  },

  methods: {
    // 获取隐私条款
    async getServiceFn() {
      let res = await get_service();
      this.serviceHtml = res.data.data;
    },

    async getPrivacy() {
      let res = await get_privacy();
      this.privacyHtml = res.data.data;
    },

    async getPrecautions() {
      let res = await get_precautions();
      this.precautionsHtml = res.data.data;
    },

    handleClose(done) {
      done();
    },
    // 下一步按钮
    async continueBtn() {
      // console.log(this.pasInfoForm);
      // return;
      this.btnLoadingBr = true;
      try {
        await this.$refs.passengerInfoRef.validate();

        // console.log({
        //   date: this.param.date,
        //   adult: this.param.adult,
        //   child: this.param.child,
        //   first_name: this.pasInfoForm.firstName,
        //   last_name: this.pasInfoForm.lastName,
        //   email: this.pasInfoForm.email,
        // });

        this.param = {
          ...this.param,
          contact: `${this.pasInfoForm.firstName} ${this.pasInfoForm.lastName}`,
          gmail: this.pasInfoForm.email,
        };

        post_orderInfo({
          date: this.param.date,
          adult: this.param.adult,
          child: this.param.child,
          student: this.param.student,
          first_name: this.pasInfoForm.firstName,
          last_name: this.pasInfoForm.lastName,
          email: this.pasInfoForm.email,
        })
          .then((res) => {
            this.btnLoadingBr = false;
            console.log(res);
            // 用户资料存本地
            this.param.biaoshi = "1";
            localStorage.setItem("orderParam", JSON.stringify(this.param));
            localStorage.setItem(
              "pasInfoForm",
              JSON.stringify({ ...this.pasInfoForm, chebox: [], chebox2: [] })
            );

            // 跳转页面
            this.$router.push({
              path: "/payment",
              // query: {
              //   id: res.data.data.id,
              // },
            });
            sessionStorage.setItem("moominOrderId", res.data.data.id);
          })
          .catch((err) => {
            this.btnLoadingBr = false;
            console.log(err);
            // console.log('数据发生变化');
            this.$message.warning("Fail to continue");
            // this.$router.push({path:'/'})
          });
      } catch (error) {
        this.btnLoadingBr = false;
      }
    },
    // a链接点击跳转
    aLianjieFn(val) {
      this.$router.push(val);
    },
    // 阅读并同意
    yueduTyFn(val) {
      // console.log(val);
      this.btnBr = val;
    },
    // 阅读并同意
    yueduTyFn2(val) {
      // console.log(val);
      this.btnBr2 = val;
    },
  },
  mounted() {
    if (this._isMobile()) {
      // 跳转至手机端路由
      if (this.$route.path != "/m_info") {
        this.$router.replace("/m_info");
      }
    } else {
      // 跳转至 pc 端路由
      if (this.$route.path != "/info") {
        this.$router.replace("/info");
      }
    }
  },
  watch: {
    $route() {},
  },
  created() {
    this.getServiceFn();
    this.getPrivacy();
    this.getPrecautions();

    console.log(this.param);
    // console.log("11111", this.pasInfoForm);
    // console.log("触发create");

    this.param = JSON.parse(localStorage.getItem("orderParam"));
    if (JSON.parse(localStorage.getItem("pasInfoForm"))) {
      // console.log("触发route");
      this.pasInfoForm = JSON.parse(localStorage.getItem("pasInfoForm"));
    }
    console.log(this.param);
    console.log(JSON.parse(localStorage.getItem("orderParam")));
    if (!JSON.parse(localStorage.getItem("orderParam"))) {
      // console.log("进来了？");
      this.$router.push({
        path: "/",
      });
    }
  },
  components: {
    Header,
    Progress,
  },
};
</script>

<style lang="less" scoped>
.lianjieDialogBox {
  /deep/.el-dialog {
    width: 920px !important;
    border-radius: 12px;
    padding-right: 10px;
    // height: 79vh;
    .el-dialog__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px;
      padding-bottom: 15px;

      // 隐藏标题
      display: none;
      margin-top: 20px;
      .el-dialog__title {
        font-weight: bold;
        font-size: 25px;
        color: #12334c;
      }
      .el-dialog__headerbtn {
        position: unset;
      }
    }

    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__footer {
      position: absolute;
      bottom: 0;
      background-color: #fff;
      width: 100%;
      padding: 30px;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    .neir {
      margin: 0 auto;
      padding: 0 40px;
      padding-right: 30px;
      color: #12334c;
      height: 431px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background: #cccccc;
        border-radius: 25px;
      }

      .neir-box {
        padding: 30px 0 0;
        word-break: break-word;
        .back {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-weight: 700;
        }
        .title {
          font-weight: bold;
          font-size: 30px;
          color: #12334c;
          margin-bottom: 40px;
        }
        .neir-main {
          margin-bottom: 28px;
          .item-da-tit {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 12px;
          }
          .item-xiao-tit {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .item-zhon-tit {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 16px;
          }
          .ppp {
            margin-bottom: 16px;
            p {
              margin-bottom: 16px;
            }
          }
          .item-main {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 40px;
            p {
              margin-bottom: 16px;
            }
            .dl {
              padding-left: 16px;
            }
          }
        }
      }
    }

    .dialog-footer {
      .el-button {
        width: 159px;
        border-radius: 7px;
        border: 1px solid #12334c;
        // color: #12334c;
        color: #fff;
        background-color: #12334c;
      }
    }
  }
}
.neiron {
  width: 1030px;
  margin: 20px auto 30px;
  padding: 0 15px;
  display: flex;
  align-items: flex-start;

  .left {
    width: 745px;
    margin-right: 20px;
    .formBox {
      // height: 439px;
      background-color: #fff;
      border-radius: 12px;
      padding: 40px;
      .formBox-tit {
        margin-bottom: 10px;
        font-size: 14px;
        color: #889098;
      }

      .formBox-form {
        /deep/.el-form-item__content {
          width: 322px;
          height: 57px;
        }
        /deep/.el-input {
          height: 100%;
        }
        /deep/.el-input__inner {
          height: 100%;
          border-radius: 7px;
          &:focus {
            border-color: #12334c;
          }
        }

        /deep/.el-form-chebox .el-form-item__content {
          height: 25px;
        }

        /deep/.el-form-item__label {
          line-height: 16px;
          font-size: 16px;
          color: #12334c;
          font-weight: bold;
        }
        /deep/.el-form-item {
          margin-bottom: 40px;
        }

        .el-form-chebox {
          /deep/.el-form-item {
            margin-bottom: 22px;
            &:last-child {
              margin-bottom: 0;
            }
            &.is-error {
              .el-checkbox {
                color: #f56c6c;
                a {
                  color: #f56c6c;
                }
              }
              .el-checkbox__inner {
                border: 1px solid #f56c6c;
              }
            }
          }
          /deep/.el-form-item__content {
            height: unset;
          }

          /deep/.el-form-item__error {
            padding-top: 0px;
          }
        }
        /deep/.el-form-item__error {
          font-size: 14px;
          padding-top: 8px;
        }

        /deep/.is-error {
          .el-input__inner:focus {
            border-color: #f56c6c;
          }
        }

        /deep/.el-form-item.is-required:not(.is-no-asterisk)
          > .el-form-item__label:before {
          content: "";
          color: #12334c;
          margin-right: 4px;
        }
        /deep/.el-form-item.is-required:not(.is-no-asterisk)
          > .el-form-item__label::after {
          content: " *";
          color: #12334c;
          margin-right: 4px;
        }

        /deep/.el-checkbox {
          width: 635px;
          display: flex;
        }
        /deep/.el-checkbox__input {
          padding-top: 2px;
        }
        /deep/.el-checkbox__label {
          white-space: pre-wrap;
          width: 100%;
          a {
            color: #0f73a0 !important;
            font-weight: 700;
          }
        }

        /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
          color: #12334c;
        }

        /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: #12334c;
          border-color: #12334c;
        }
      }
    }

    .totalBtn {
      height: 122px;
      background-color: #fff;
      margin-top: 20px;
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px;
      color: #12334c;

      .totalBtn-lft {
        font-size: 18px;
        font-weight: bold;
      }

      .totalBtn-rig {
        display: flex;
        align-items: center;
        p {
          font-weight: bold;
          font-size: 20px;
        }
        .totalBtn-btn {
          margin-left: 20px;
          width: 142px;
          height: 42px;
          border-radius: 7px;
          background: #12334c;
          color: #fff;
          font-size: 16px;
        }
      }
    }
  }

  .right {
    width: 235px;
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;

    .right-tit {
      border-bottom: 1px solid #b2bac13d;
      padding-bottom: 20px;
      font-size: 18px;
      color: #12334c;
      font-weight: bold;
      p {
        margin-bottom: 10px;
        line-height: 24px;
      }
      span {
        font-size: 14px;
      }
    }
    .right-adult,
    .right-child {
      height: 86px;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 14px;
      border-bottom: 1px solid #b2bac13d;
      .item {
        display: flex;
        justify-content: space-between;
        span {
          font-weight: 700;
        }
      }
    }
    .right-total {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      font-weight: bold;
      font-size: 14px;
    }
  }
}

// .btm {
//   position: absolute;
//   bottom: 0;
//   width: 100%;
//   background-color: #fff;
//   min-width: 1000px;
//   padding: 20px;

//   .btm-box {
//     display: flex;
//     justify-content: space-between;
//     width: 1030px;
//     padding: 0 15px;
//     margin: 0 auto;
//     span,
//     a {
//       font-weight: 700;
//     }
//   }
// }
</style>